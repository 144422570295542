export const getServerUrl = () => {
  if (import.meta.env.PROD) {
    return 'https://kubaruba.ru'; // В продакшене используем без слеша в конце
  }
  
  if (import.meta.env.STAGING) {
    return 'http://188.68.223.199'; // URL staging сервера без порта 3000
  }
  
  return 'http://localhost:3000'; // Локальный URL для разработки
};

export const isDevelopment = () => import.meta.env.DEV;
export const isStaging = () => import.meta.env.STAGING;
export const isProduction = () => import.meta.env.PROD; 