import type { GameConfig } from '@shared/types';
import type { PerudoGameState } from './types';

const config = {
  name: 'Перудо',
  description: 'Кидай кости и отгадывай, что за число выпало у других игроков.',
  minPlayers: 1,
  maxPlayers: 10,
  getDiceCount: (playerCount: number): number => {
    if (playerCount <= 4) return 5;
    if (playerCount <= 6) return 4;
    return 3; // Для 7-10 игроков
  },
  // Добавляем длительность анимации Dudo в миллисекундах
  dudoAnimationDuration: 9000
} as const;

export default config; 