import { CrocodilePlayer } from '@shared/games/crocodile/types';
import type { 
  Player, 
  BaseGameState
} from '@shared/types';

// Определяем все события как const enum
export const enum SocketEvents {
  // Game events
  GAME_START = 'game:start',
  GAME_STATE = 'game:state',
  GAME_ENDED = 'game:ended',
  GAME_ERROR = 'game:error',
  GAME_ACTION = 'game:action',
  GAME_REQUEST_STATE = 'game:client:request_state',

  // Room events
  ROOM_CREATE = 'room:create',
  ROOM_CREATED = 'room:created',
  ROOM_JOIN = 'room:join',
  ROOM_JOINED = 'room:joined',
  ROOM_PLAYERS_UPDATED = 'room:players_updated',
  ROOM_ERROR = 'room:error',
  ROOM_UPDATED = 'room:updated',
  ROOM_CHANGE_NAME = 'room:client:change_name',
  ROOM_SELECT_GAME = 'room:client:select_game',
  ROOM_GAME_SELECTED = 'room:game_selected',

  // Perudo specific events
  PERUDO_STARTED = 'perudo:started',
  PERUDO_BET = 'perudo:bet',
  PERUDO_DUDO = 'perudo:dudo',
  PERUDO_DICE_ROLLED = 'perudo:dice_rolled',
  PERUDO_BET_MADE = 'perudo:bet_made',
  PERUDO_DUDO_RESULT = 'perudo:dudo_result',

  // Crocodile specific events
  CROCODILE_STARTED = 'crocodile:started',
  CROCODILE_SELECT_DIFFICULTY = 'crocodile:client:select_difficulty',
  CROCODILE_DIFFICULTY_UPDATED = 'crocodile:server:difficulty_updated',
  CROCODILE_ROUND_START = 'crocodile:client:round_start',
  CROCODILE_ROUND_STARTED = 'crocodile:server:round_started',
  CROCODILE_POINTS_UPDATE = 'crocodile:client:points_update',
  CROCODILE_POINTS_UPDATED = 'crocodile:server:points_updated',
  CROCODILE_ROUND_UPDATE = 'crocodile:client:round_update',
  CROCODILE_ROUND_UPDATED = 'crocodile:server:round_updated',
  CROCODILE_CHANGE_PLAYER = 'crocodile:client:change_player',
  CROCODILE_PLAYER_CHANGED = 'crocodile:server:player_changed'
}

// Группируем события для удобства использования
export const SOCKET = {
  GAME: {
    CLIENT: {
      START: SocketEvents.GAME_START,
      ACTION: SocketEvents.GAME_ACTION,
      REQUEST_STATE: SocketEvents.GAME_REQUEST_STATE
    },
    SERVER: {
      STARTED: SocketEvents.GAME_START,
      STATE_UPDATED: SocketEvents.GAME_STATE,
      ENDED: SocketEvents.GAME_ENDED,
      ERROR: SocketEvents.GAME_ERROR
    }
  },
  ROOM: {
    CLIENT: {
      CREATE: SocketEvents.ROOM_CREATE,
      JOIN: SocketEvents.ROOM_JOIN,
      CHANGE_NAME: SocketEvents.ROOM_CHANGE_NAME,
      SELECT_GAME: SocketEvents.ROOM_SELECT_GAME
    },
    SERVER: {
      CREATED: SocketEvents.ROOM_CREATED,
      JOINED: SocketEvents.ROOM_JOINED,
      PLAYERS_UPDATED: SocketEvents.ROOM_PLAYERS_UPDATED,
      ERROR: SocketEvents.ROOM_ERROR,
      GAME_SELECTED: SocketEvents.ROOM_GAME_SELECTED
    }
  },
  PERUDO: {
    CLIENT: {
      MAKE_BET: SocketEvents.PERUDO_BET,
      CALL_DUDO: SocketEvents.PERUDO_DUDO
    },
    SERVER: {
      DICE_ROLLED: SocketEvents.PERUDO_DICE_ROLLED,
      BET_MADE: SocketEvents.PERUDO_BET_MADE,
      DUDO_RESULT: SocketEvents.PERUDO_DUDO_RESULT
    }
  },
  CROCODILE: {
    CLIENT: {
      SELECT_DIFFICULTY: SocketEvents.CROCODILE_SELECT_DIFFICULTY,
      ROUND_START: SocketEvents.CROCODILE_ROUND_START,
      POINTS_UPDATE: SocketEvents.CROCODILE_POINTS_UPDATE,
      ROUND_UPDATE: SocketEvents.CROCODILE_ROUND_UPDATE,
      CHANGE_PLAYER: SocketEvents.CROCODILE_CHANGE_PLAYER
    },
    SERVER: {
      DIFFICULTY_UPDATED: SocketEvents.CROCODILE_DIFFICULTY_UPDATED,
      ROUND_STARTED: SocketEvents.CROCODILE_ROUND_STARTED,
      POINTS_UPDATED: SocketEvents.CROCODILE_POINTS_UPDATED,
      ROUND_UPDATED: SocketEvents.CROCODILE_ROUND_UPDATED,
      PLAYER_CHANGED: SocketEvents.CROCODILE_PLAYER_CHANGED
    }
  }
} as const;

// Типы для событий
export interface ServerToClientEvents {
  [SOCKET.GAME.SERVER.STARTED]: (state: BaseGameState) => void;
  [SOCKET.GAME.SERVER.STATE_UPDATED]: (state: BaseGameState) => void;
  [SOCKET.GAME.SERVER.ENDED]: (result: { winner: Player; restartGame: boolean }) => void;
  [SOCKET.GAME.SERVER.ERROR]: (data: { message: string }) => void;
  [SOCKET.ROOM.SERVER.CREATED]: (data: { roomId: string }) => void;
  [SOCKET.ROOM.SERVER.JOINED]: (data: { roomId: string }) => void;
  [SOCKET.ROOM.SERVER.PLAYERS_UPDATED]: (data: { players: Player[]; owner: string }) => void;
  [SOCKET.ROOM.SERVER.ERROR]: (data: { message: string }) => void;
  [SOCKET.ROOM.SERVER.GAME_SELECTED]: (data: { gameType: string }) => void;
  [SOCKET.PERUDO.SERVER.DICE_ROLLED]: (dice: number[]) => void;
  [SOCKET.PERUDO.SERVER.DUDO_RESULT]: (result: {
    challengerClientId: string;
    betMakerClientId: string;
    bet: Bet;
    actualQuantity: number;
    betWasCorrect: boolean;
    losingPlayerClientId: string;
    allDice: Record<string, number[]>;
  }) => void;
  [SOCKET.CROCODILE.SERVER.DIFFICULTY_UPDATED]: (data: { difficulty: 'easy' | 'medium' | 'hard' | null}) => void;
  [SOCKET.CROCODILE.SERVER.ROUND_STARTED]: (data: { word: string; time: number }) => void;
  [SOCKET.CROCODILE.SERVER.POINTS_UPDATED]: (data: { players: Player[] }) => void;
  [SOCKET.CROCODILE.SERVER.ROUND_UPDATED]: (data: { roundNumber: number }) => void;
  [SOCKET.CROCODILE.SERVER.PLAYER_CHANGED]: (data: { currentPlayer: string }) => void;
}

export interface ClientToServerEvents {
  [SOCKET.GAME.CLIENT.START]: () => void;
  [SOCKET.ROOM.CLIENT.CREATE]: (data: { gameId?: string }) => void;
  [SOCKET.ROOM.CLIENT.JOIN]: (data: { roomId: string; playerName?: string }) => void;
  [SOCKET.ROOM.CLIENT.SELECT_GAME]: (data: { gameType: string }) => void;
  [SOCKET.PERUDO.CLIENT.MAKE_BET]: (data: { quantity: number; value: number }) => void;
  [SOCKET.PERUDO.CLIENT.CALL_DUDO]: () => void;
  [SOCKET.CROCODILE.CLIENT.SELECT_DIFFICULTY]: (data: { roomId: string; difficulty: 'easy' | 'medium' | 'hard' | null }) => void;
  [SOCKET.CROCODILE.CLIENT.ROUND_START]: (data: { roomId: string; word: string; time: number }) => void;
  [SOCKET.CROCODILE.CLIENT.POINTS_UPDATE]: (data: { roomId: string; players: CrocodilePlayer[] }) => void;
  [SOCKET.CROCODILE.CLIENT.ROUND_UPDATE]: (data: { roomId: string; roundNumber: number }) => void;
  [SOCKET.CROCODILE.CLIENT.CHANGE_PLAYER]: (data: { roomId: string }) => void;
}

export interface Bet {
  playerClientId: string;
  quantity: number;
  value: number;
} 