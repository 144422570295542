import type { GameConfig } from '@shared/types';

const config = {
  name: 'Крокодил',
  description: 'Отгадай слово или фразу, которую показывает ведущий, за отведенное время.',
  minPlayers: 1,
  maxPlayers: 10,
  getRoundCount: (playerCount: number): number => {
    return playerCount * 3;
  }
} as const;

export default config; 