import { ref, onMounted, watch } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import { getServerUrl } from '@/config/environment'

const PLAYER_UUID_KEY = 'playerUUID'

interface User {
  id: string
  name: string
  email: string
  avatar_url?: string
  provider: string
}

export function useAuth() {
  const user = ref<User | null>(null)
  const loading = ref(true)
  const baseUrl = getServerUrl()

  function getPlayerId(): string {
    let uuid = localStorage.getItem(PLAYER_UUID_KEY)
    if (!uuid) {
      uuid = uuidv4()
      localStorage.setItem(PLAYER_UUID_KEY, uuid)
    }
    return uuid
  }

  function updatePlayerId(authId: string) {
    localStorage.setItem(PLAYER_UUID_KEY, authId)
  }

  function resetPlayerId() {
    localStorage.removeItem(PLAYER_UUID_KEY)
  }

  // Следим за изменениями авторизации
  watch(user, (newUser) => {
    if (newUser) {
      updatePlayerId(newUser.id)
    } else {
      resetPlayerId()
    }
  })

  async function checkAuth() {
    loading.value = true
    try {
      const silentCheck = await fetch(`${baseUrl}/api/user`, {
        credentials: 'include',
        headers: {
          'X-Silent-Check': 'true'
        }
      })

      if (silentCheck.status === 204) {
        user.value = null
        return
      }

      if (silentCheck.ok) {
        const data = await silentCheck.json()
        user.value = data.data
      } else {
        user.value = null
      }
    } catch (err: unknown) {
      if (err instanceof Error) {
        console.warn('Ошибка при проверке авторизации:', err.message)
      }
      user.value = null
    } finally {
      loading.value = false
    }
  }

  async function logout() {
    try {
      window.location.href = `${baseUrl}/auth/logout`
      user.value = null
    } catch (err) {
      console.error('Failed to logout:', err)
    }
  }

  onMounted(checkAuth)

  return {
    user,
    loading,
    checkAuth,
    logout,
    getPlayerId
  }
} 