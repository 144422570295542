<script setup lang="ts">
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { Transition } from 'vue'
import { useRouter } from 'vue-router'
import IconLogo from './../icons/IconLogo.vue'
import IconMenu from './../icons/IconMenu.vue'
import { featuredGame } from '@shared/games'
import NameEditor from './NameEditor.vue'
import IconPremium from './../icons/IconPremium.vue'
import PremiumButton from './PremiumButton.vue'

const router = useRouter()

function handleCreateRoom(close: () => void) {
  router.push({
    path: '/room',
    query: { gameId: featuredGame }
  })
  close()
}

function handleProfile(close: () => void) {
  router.push('/profile')
  close()
}
</script>

<template>
  <header>
    <div class="wrapper">
      <IconLogo />
      <router-link to="/" class="home-link">
        <h1>KUBARUBA</h1>
      </router-link>
      
      <Menu as="div" class="menu-container">
        <MenuButton class="menu-button">
          <IconMenu class="menu-icon" />
        </MenuButton>

        <Transition
          as="template"
          enter="transition ease-out duration-100"
          enter-from="transform opacity-0 scale-95"
          enter-to="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leave-from="transform opacity-100 scale-100"
          leave-to="transform opacity-0 scale-95"
        >
          <MenuItems class="menu-items">
            <div class="menu-content">
              <MenuItem v-slot="{ active, close }">
                <button
                  @click="handleProfile(close)"
                  :class="[
                    active ? 'menu-item-active' : 'menu-item',
                    'menu-item-button'
                  ]"
                >
                  Мой профиль
                </button>
              </MenuItem>

              <MenuItem v-slot="{ active, close }">
                <button
                  @click="handleCreateRoom(close)"
                  :class="[
                    active ? 'menu-item-active' : 'menu-item',
                    'menu-item-button'
                  ]"
                >
                  Создать комнату
                </button>
              </MenuItem>

              <MenuItem v-slot="{ close }">
                <div class="menu-premium-button">
                  <PremiumButton @click="close" />
                </div>
              </MenuItem>
            </div>
          </MenuItems>
        </Transition>
      </Menu>
    </div>
  </header>
</template>

<style scoped>
header {
  position: sticky;
  top: 0px;
  color: var(--kr-color-white);
  background: var(--kr-color-back-dark-2);
  z-index: 100;
  height: var(--header-height);
}

header .wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: var(--site-width-max);
  gap: 0.5rem;
  margin: 0 auto;
  padding: 0 2rem;
  height: 100%;
}

.home-link {
  text-decoration: none;
  color: inherit;
  background: none;
}

.home-link:hover {
  text-decoration: none;
  background: none;
  color: inherit;
}

.menu-container {
  position: relative;
  margin-left: auto;
}

.menu-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  width: 2.5rem;
  color: var(--kr-color-white);
  background: none;
  border: none;
  cursor: pointer;
  border-radius: 0.375rem;
  transition: background-color 0.2s;
}

.menu-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.menu-icon {
  width: 2rem;
  height: 2rem;
}

.menu-items {
  position: fixed;
  top: var(--header-height);
  left: 0;
  right: 0;
  width: 100vw;
  margin: 0;
  background: var(--kr-color-back-dark-2);
  z-index: 50;
}

.menu-content {
  width: 100%;
  max-width: var(--site-width-max);
  margin: 0 auto;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.menu-item,
.menu-item-active {
  display: block;
  width: 100%;
  max-width: 320px;
  padding: 1rem;
  text-align: center;
  font-size: 1rem;
  color: var(--kr-color-white);
  background: none;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s;
  border-radius: 8px;
}

.menu-item:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.menu-item-active {
  background-color: rgba(255, 255, 255, 0.2);
}

.menu-item-button {
  width: 100%;
}

.menu-item-button:last-child {
  background-color: inherit;
  color: inherit;
  font-weight: inherit;
  animation: none;
}

.menu-item-button:last-child:hover {
  filter: none;
  background-color: rgba(255, 255, 255, 0.1);
}

.menu-item-button:last-child:active {
  filter: none;
  transform: none;
}

.premium-icon {
  margin-left: 0.5rem;
  color: var(--kr-color-juice-1);
}

.menu-premium-button {
  width: 100%;
  max-width: 320px;
}
</style>
