import perudoConfig from './perudo/config';
import crocodileConfig from './crocodile/config';
// import quizConfig from './quiz/config';
// import backshotConfig from './backshot/config';

// Экспортируем только конфигурации игр
export const gameConfigs = {
  perudo: perudoConfig,
  crocodile: crocodileConfig,
  // quiz: quizConfig,
  // backshot: backshotConfig
} as const;

// Определяем игру, которую хотим продвигать
export const featuredGame = 'perudo' as keyof typeof gameConfigs;

export default gameConfigs; 