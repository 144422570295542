import { io, Socket } from 'socket.io-client';
import { v4 as uuidv4 } from 'uuid';
import type { 
  ServerToClientEvents,
  ClientToServerEvents 
} from '@shared/constants/events';
import { SOCKET } from '@shared/constants/events';

class ClientSocketService {
  private socket: Socket<ServerToClientEvents, ClientToServerEvents> | null = null;
  private registeredHandlers = new Set<string>();
  private clientId: string | null = null;
  private readonly CLIENT_ID_KEY = 'game_client_id';

  connect() {
    // Если сокет уже подключен, просто возвращаем его
    if (this.socket?.connected) {
      console.log('Socket already connected, id:', this.socket.id, 'clientId:', this.clientId);
      return this.socket;
    }

    // Всегда отключаем существующее соединение перед созданием нового
    if (this.socket) {
      console.log('Disconnecting existing socket before reconnecting');
      this.socket.disconnect();
      this.socket = null;
    }
    
    const isDebugMode = import.meta.env.DEV && localStorage.getItem('debug_mode') === 'true';
    
    // В режиме отладки генерируем новый ID для каждого подключения
    // В обычном режиме используем сохраненный ID
    if (!this.clientId || isDebugMode) {
      this.clientId = isDebugMode ? 
        this.generateUUID() : 
        this.getOrCreateClientId();
      
      console.log('Generated new client ID:', this.clientId);
    } else {
      console.log('Using existing client ID:', this.clientId);
    }

    const serverUrl = import.meta.env.DEV 
      ? 'http://localhost:3000'
      : window.location.origin;

    try {
      console.log('Connecting to socket server:', serverUrl);
      this.socket = io(serverUrl, {
        query: { clientId: this.clientId },
        reconnectionAttempts: 5,
        reconnectionDelay: 1000,
        timeout: 10000,
        autoConnect: true
      });
      
      this.socket.on('connect', () => {
        console.log('Socket connected, id:', this.socket?.id, 'clientId:', this.clientId);
      });

      this.socket.on('connect_error', (error) => {
        console.error('Socket connection error:', error);
      });

      return this.socket;
    } catch (error) {
      console.error('Error creating socket connection:', error);
      throw error;
    }
  }

  private getOrCreateClientId(): string {
    let clientId = localStorage.getItem(this.CLIENT_ID_KEY);
    
    if (!clientId) {
      clientId = this.generateUUID();
      localStorage.setItem(this.CLIENT_ID_KEY, clientId);
    }
    
    return clientId;
  }

  private generateUUID(): string {
    return uuidv4();
  }

  getClientId(): string | null {
    return this.clientId;
  }

  get id() {
    return this.socket?.id;
  }

  get connected() {
    return this.socket?.connected || false;
  }

  disconnect() {
    if (this.socket) {
      this.socket.disconnect();
      this.socket = null;
    }
  }

  emit<K extends keyof ClientToServerEvents>(
    event: K,
    ...args: Parameters<ClientToServerEvents[K]>
  ) {
    if (!this.socket) {
      console.error('Socket not connected, trying to reconnect...');
      this.connect();
    }
    
    if (!this.socket?.connected) {
      console.error('Socket not connected, cannot emit event:', event);
      return;
    }
    
    // For certain events, add clientId if not already present
    if (event === SOCKET.PERUDO.CLIENT.MAKE_BET || 
        event === SOCKET.PERUDO.CLIENT.CALL_DUDO) {
      const data = args[0] as any;
      if (data && !data.clientId) {
        data.clientId = this.clientId;
      }
    }
    
    console.log('Emitting event:', event, 'with args:', args);
    (this.socket as any).emit(event, ...args);
  }

  on<K extends keyof ServerToClientEvents>(
    event: K,
    callback: ServerToClientEvents[K]
  ) {
    if (!this.socket) {
      console.error('Socket not connected, trying to reconnect...');
      this.connect();
    }
    if (!this.socket) {
      console.error('Socket connection failed, cannot register handler for:', event);
      return;
    }
    
    const handlerId = `${event}`;
    console.log(`Adding listener for ${event}`, {
      beforeRegistration: {
        hasListeners: this.socket.hasListeners(event),
        isRegistered: this.registeredHandlers.has(handlerId)
      }
    });
    
    // Если такой обработчик уже зарегистрирован, сначала удалим его
    if (this.registeredHandlers.has(handlerId)) {
      console.log(`Handler for ${event} already registered, removing old handler first`);
      this.off(event);
    }
    
    // Добавляем новый обработчик
    this.socket.on(event, callback as any);
    this.registeredHandlers.add(handlerId);
    
    console.log(`Added listener for ${event}, hasListeners:`, this.socket.hasListeners(event));
  }

  off<K extends keyof ServerToClientEvents>(
    event: K,
    callback?: ServerToClientEvents[K]
  ) {
    if (!this.socket) return;
    
    const handlerId = `${event}`;
    console.log(`Removing handler for ${event}`, {
      beforeRemoval: {
        hasListeners: this.socket.hasListeners(event),
        isRegistered: this.registeredHandlers.has(handlerId)
      }
    });
    
    if (callback) {
      this.socket.off(event, callback as any);
    } else {
      this.socket.off(event);
    }
    
    this.registeredHandlers.delete(handlerId);
    
    console.log(`After removing handler for ${event}`, {
      afterRemoval: {
        hasListeners: this.socket.hasListeners(event),
        isRegistered: this.registeredHandlers.has(handlerId)
      }
    });
  }

  hasListeners(event: keyof ServerToClientEvents): boolean {
    if (!this.socket) return false;
    const hasSocketListeners = this.socket.hasListeners(event);
    console.log(`Checking if socket has listeners for ${event}:`, hasSocketListeners);
    return hasSocketListeners;
  }

  toggleDebugMode(enabled: boolean): void {
    if (enabled) {
      localStorage.setItem('debug_mode', 'true');
    } else {
      localStorage.removeItem('debug_mode');
    }
    console.log(`Debug mode ${enabled ? 'enabled' : 'disabled'}`);
  }

  isDebugMode(): boolean {
    return import.meta.env.DEV && localStorage.getItem('debug_mode') === 'true';
  }

  getSocket(): Socket<ServerToClientEvents, ClientToServerEvents> | null {
    if (!this.socket) {
      console.log('Socket not connected, trying to connect...');
      this.connect();
    }
    return this.socket;
  }
}

export const socketService = new ClientSocketService(); 