<template>
  <div v-if="isDev" class="debug-panel">
    <div class="debug-content">
      <div class="debug-item">
        <label>
          <input 
            type="checkbox" 
            :checked="isDebugMode" 
            @change="toggleDebugMode"
          />
          Multi-tab
        </label>
      </div>
      <div class="debug-item">
        <div class="debug-info">
          ID: {{ displayClientId }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed, onBeforeUnmount } from 'vue';
import { socketService } from '@/services/socket';

const isDev = import.meta.env.DEV;
const isDebugMode = ref(false);
const clientId = ref('');
let updateInterval: number | null = null;

// Сокращаем ID для отображения
const displayClientId = computed(() => {
  if (!clientId.value) return 'No connect';
  // Показываем только первые 8 символов ID
  return clientId.value.substring(0, 8) + '...';
});

function toggleDebugMode(event: Event) {
  const enabled = (event.target as HTMLInputElement).checked;
  socketService.toggleDebugMode(enabled);
  isDebugMode.value = enabled;
}

function updateClientId() {
  // Проверяем, подключен ли сокет, не вызывая подключение
  if (socketService.connected) {
    const id = socketService.getClientId();
    if (id) {
      clientId.value = id;
    }
  } else {
    clientId.value = 'No connect';
  }
}

onMounted(() => {
  isDebugMode.value = socketService.isDebugMode();
  
  // Получаем clientId из localStorage, не инициируя подключение
  const savedClientId = localStorage.getItem('game_client_id');
  if (savedClientId) {
    clientId.value = savedClientId;
  }
  
  // Обновляем ID каждую секунду
  updateInterval = window.setInterval(updateClientId, 1000);
});

onBeforeUnmount(() => {
  if (updateInterval) {
    clearInterval(updateInterval);
  }
});
</script>

<style scoped>
.debug-panel {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  color: #00ff00;
  padding: 5px 10px;
  font-family: monospace;
  z-index: 9999;
  height: auto;
  max-height: 30px;
}

.debug-content {
  font-size: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.debug-item {
  margin: 0 15px 0 0;
  display: flex;
  align-items: center;
}

.debug-info {
  word-break: break-all;
}
</style> 