<script setup>
import HeaderSite from './components/HeaderSite.vue'
import DebugPanel from './components/DebugPanel.vue'
import { useAuth } from '@/composables/useAuth'

const { loading } = useAuth()
</script>

<template>
  <div v-if="loading" class="loading-overlay">
    <div class="loading-spinner">Загрузка...</div>
  </div>
  <div v-else>
    <HeaderSite />
    <router-view />
    <DebugPanel />
  </div>
</template>

<style>
main {
  max-width: var(--site-width-max);
  margin: 0 auto;
  padding: 2rem;
  font-weight: normal;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--kr-color-back-dark-2);
  color: var(--kr-color-white);
  z-index: 9999;
}

.loading-spinner {
  font-size: 1.2rem;
}
</style>
